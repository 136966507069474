<script>
export default {
  name: 'Error500View',
  methods: {
    goToHome() {
      window.location = '/';
    },
  },
};

</script>

<template>
  <main
    class="
      ErrorsView
      w-100 h-100
      d-flex justify-content-center align-items-center
      emobg-background-color-secondary-dark
    "
    data-test-id="errors-view"
  >
    <div class="p-5 emobg-background-color-danger">
      <ui-icon
        :icon="ICONS.alertAlone"
        :color="GRAYSCALE.white"
        :size="ICONS_SIZES.huge"
      />

      <div class="mb-6 emobg-color-white emobg-font-x-large">
        {{ $t('modal.signup.error.title') }}
      </div>

      <div class="emobg-color-ink emobg-border-radius-medium emobg-background-color-white p-3">
        <div class="d-flex justify-content-center mb-6">
          <ui-button @clickbutton="goToHome">
            {{ $t('modal.signup.error.try_again') }}
          </ui-button>
        </div>

        <div
          class="emobg-color-ink font-l px-6 mb-5"
          v-html="$t('modal.signup.error.message')"
        />
      </div>
    </div>
  </main>
</template>
